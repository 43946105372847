.bg-alert{
    background-color: rgba(0, 0, 0, 0.7) !important;
    color: white;
    text-align: center;
    transition: all .6s ease-in-out;
    font-size: 20px;
    height: 0px;
    border-top: none;
    width: 100%;
  }
  
  .show-alert{
    height: inherit;
    border-top: 1px solid var(--golden);
    z-index: 5000;
  }
  
  .error-label{
    color:red;
  }
  input{
    padding: 15px ;
    height: 40px !important;
    border-radius: 8px;
    transition: all .4s !important;
    background-color: white;
    outline: none;
  }
  input:hover, input:focus{
    background-color: var(--naranja-05);
    
  }
  