#container-messages{
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    background-color: #fff5e8;
    height: 80vh;
}

#container-contacts{
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    background-color: #ffffff;
    height: 80vh;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

#container-chat{
    padding: 10px;
    height: 92%;
    overflow-y: auto;
    overflow-x: hidden;
}

#container-message{
    width: 100%;
    height: 8%;
    background-color: #e1e1e1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.circle-user{
    width: 50px;
    height: 50px;
    background-color: rgba(158, 158, 158, 0.9);
    border-radius: 50%;
}

.circle-user img{
    width: 50px;
    height: 50px;
}

.bg-chat{
    background-color: #d9fdd3;
}
.bg-selected{
    background-color: #e9e9e9;
}