body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  
}
:root{
  --azul: rgb(52,63,75);
  --azul-light: rgb(236, 246, 255);
  --azul-20: rgba(52,63,75,0.2);
  --naranja: #ab6435;
  --naranja-dark: rgb(167, 100, 0);
  --naranja-hover: rgb(199, 120, 1);
  --naranja-10:rgba(241,144,1,0.1);
  --naranja-05: rgb(255, 247, 234);
  --naranja-transp:rgb(255, 242, 224);
  --white-30:rgba(255,255,255,.3);
  --white-05:rgba(255,255,255,.05);
  --gris-60:rgba(0,0,0,0.6);
  --gris-10:rgba(0,0,0,0.05);
  --verde: rgb(157, 255, 171);
  --gray-light: rgb(241, 239, 239);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.size-icon-35{
  width: 35px;
}

.size-icon-25{
  width: 25px;
}

.size-icon-15{
  width: 15px;
}


.cursor{
  cursor: pointer;
}
.font-15{
  font-size: 15px;
}
.font-14{
  font-size: 14px;
}
.font-20{
  font-size: 20px;
}
.font-18{
  font-size: 18px;
}
.font-13{
  font-size: 13px;
}
.font-11{
  font-size: 11px;
}
.font-12{
  font-size: 12px;
}



.img-product{
  overflow: hidden;
  width: 100%;
  height: 250px;
}

@media (min-width: 992px)
{
  .modal-xl {
      max-width: 1090px;
  }
}
.overflow-y::-webkit-scrollbar {
  width: 2px;

  
}
.css-nvf14r-ToastContainer{
  top: 80px !important;
}

.cursor-pointer{
  cursor: pointer;
}

.img-menu{
  width: 50px !important;
}

.img-icon{
  width: 60px;
}

.pt-6{
  padding-top: 4rem!important;
}
.form-input{
 width: 450px; 
}

.icon-md{
  width: 25px;
}

.img-order {
  max-width: 100px;
}

.bg-azul{
  background-color: #b9deff;
}

@media (min-width: 992px){
  .modal-lg{
    max-width: 1024px;
  }
}

.table-sm td, .table-sm th{
  
}

.css-qbdosj-Input{
  /* padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important; */
  height: 15px;
  /* overflow:none; */
}

.loader {
  position: absolute;
  z-index: 7000;
  background-color: rgba(36, 40, 50, 0.8);
  height: 100%;
  display: flex;
}

.text-error{
  color: red;
}

.circle-detailing{
  width: 60px;
  height: 30px;
  border-radius: 10px;
  cursor: pointer;
}