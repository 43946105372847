#root{
  height: 100vh;
}
:root{
  --naranja-hover: rgb(199, 120, 1);
  --naranja-05: rgb(255, 247, 234);
  --azul: rgb(52,63,75);
  --naranja: rgb(241,144,1);
  --blue-capio:#144881;
}
.btn-confirm{
  transition: all .3s;
}
.btn-confirm:hover{
  border-radius: 13px;
  background-color: var(--naranja-hover);
}
.btn-confirm button {
  transition: all .3s;
  
}
.btn-confirm:hover > button {
  border-radius: 10px;
  letter-spacing: 1px;
  background-color: var(--naranja-hover);
  color: white;
}
.inputForm{
  transition: all .3s;
  border: 0px;
  padding: 20px 0px !important;
}
.inputForm:hover{
  background-color: var(--naranja-05);
}
.logo{
  position: absolute;
  top: -130px;
  width: 160px;
}
.border-naranja{
  transition: all .4s;
  border: 2px solid var(--naranja);
  box-shadow: 0px 0px 15px rgba(241,144,1,1);
  animation-name:border;
  animation-duration: 1.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes border {
  0%{
    box-shadow: 0px 0px 15px rgba(241,144,1,1);
  }
  50%{
    box-shadow: 0px 0px 0px rgba(241,144,1,0);
  }
  100%{
    box-shadow: 0px 0px 15px rgba(241,144,1,1);
  }
}


.card-custom{
  /* background-color: rgba(14,14,15,0.8); */
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 4px 1px 6px -2px rgba(0,0,0,0.9); 
  box-shadow: 4px 1px 6px -2px rgba(0,0,0,0.9);
  min-height: 600px;
  border-bottom-left-radius: 40px;
}

.bg-burger{
  background-image: url(../../Images/fondo_car.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 600px;
}
.bg-texture-login{
  background-image: url(../../Images/fondo_470x550.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 600px;
}

.bg-image-login{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  position: absolute;;
}

.img-logo-login{
  z-index: 6000;
  width: 60%;
}

.container-image{
  height: 100%;
}

.text-blue{
  color:var(--blue-capio)
}